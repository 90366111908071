import { EDateTypes, EFilterType } from '@/enums';

interface IValidateProps {
  filterName: string;
  filterItems: FilterItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setErrors: any;
}

const validateFiltersFields = ({ filterName, filterItems, setErrors }: IValidateProps): boolean => {
  const newErrors: { [key: string]: { [field: string]: string } } = {};

  if (!filterName.trim()) {
    newErrors.filterName = { filterName: 'Filter name is required.' };
  }

  filterItems.forEach((item) => {
    const itemErrors: { [field: string]: string } = {};
    if (item?.itemType === EFilterType.TEXT && item?.itemSelectionText?.length === 0) {
      itemErrors.itemSelectionText = 'At least one option is required';
    }

    if (item?.itemType === EFilterType.NUMERIC) {
      const from = Number(item?.itemSelectionNumeric.from);
      const to = Number(item?.itemSelectionNumeric.to);

      // Check if min or max are NaN
      if (Number.isNaN(from) || Number.isNaN(to)) {
        itemErrors.itemSelectionNumeric = 'Both min and max must be valid numbers';
      } else if (((item?.itemSelectionNumeric.from !== 0)) && from > to) {
        itemErrors.itemSelectionNumeric = 'Min must be less than or equal to max';
      } else if (item?.itemSelectionNumeric.to === 0 && item?.itemSelectionNumeric.to === 0) {
        itemErrors.itemSelectionNumeric = 'At least one field is required';
      }
    }

    if (item?.itemType === EFilterType.DATE && item?.itemTypeOfSelectionDate === EDateTypes.RANGE && !item?.itemSelectionDate.from && !item?.itemSelectionDate.to) {
      itemErrors.itemSelectionDate = 'Date range is required';
    }
    if (item?.itemType === EFilterType.DATE && item?.itemTypeOfSelectionDate === EDateTypes.BEFORE && !item?.itemSelectionDateBefore) {
      itemErrors.itemSelectionDateBefore = 'Date before is required';
    }
    if (item?.itemType === EFilterType.DATE && item?.itemTypeOfSelectionDate === EDateTypes.AFTER && !item?.itemSelectionDateAfter) {
      itemErrors.itemSelectionDateAfter = 'Date after is required';
    }

    if (Object.keys(itemErrors)?.length > 0) {
      newErrors[item?.id] = itemErrors;
    }
  });

  setErrors(newErrors);
  return Object.keys(newErrors)?.length === 0;
};

export default validateFiltersFields;
