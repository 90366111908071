import CountUp from 'react-countup';
import { useQuery } from '@tanstack/react-query';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { cn } from '@/lib/utils';
import useTranslation from '@/hooks/useTranslation';
import { Skeleton } from '@/components/ui/skeleton';
import useReportStore from '@/store/ReportStore';
import axiosInstance from '@/axios/axiosInstance';
import { EPanelIntegration } from '@/enums';
import {
  translateAddToReport, translateAvgTimeToCompletion, translateCompletedInterviews,
  translateInterviewsInProgress, translateCompletionRate, translateStatistics, translateTotal,
} from '../project_results/resultsPageTranslations';

interface IStatistics {
  data: IData;
  isLoading: boolean;
  showSelect: boolean;
  project_id: string | undefined;
}

interface IData {
  completed_interviews: number;
  started_interviews: number;
  completion_rate: number;
  average_time: number;
  gender_distribution: IGender;
  average_age: number;
}

interface IGender {
  men: number;
  women: number;
}

function Statistics({ data, isLoading, showSelect, project_id }: IStatistics) {
  const { lang } = useTranslation();
  const { currentTheme } = useTheme();

  const { data: singleProjectData } = useQuery({
    queryFn: () => axiosInstance.get(`projects/get/${project_id}`),
    queryKey: ['single_project_data', { project_id }],
    select: (projData) => projData?.data,
  });

  const analysisLanguage = singleProjectData?.analysis_language;

  const totalSeconds = Math.floor(data?.average_time as number / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;
  // Add leading zeros if needed
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  const reportData = useReportStore((state) => state.reportData);
  const setReportData = useReportStore((state) => state.setReportData);

  const isStatisticChecked = useReportStore((state) => state.isStatisticChecked);
  const setIsStatisticChecked = useReportStore((state) => state.setIsStatisticChecked);

  const onReportSave = () => {
    setIsStatisticChecked(!isStatisticChecked);
    if (!isStatisticChecked) {
      setReportData({ ...reportData, statistics: data });
    } else {
      const { statistics, ...restData } = reportData;
      setReportData(restData);
    }
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-start justify-between w-full">
        <h3
          style={{ color: currentTheme?.primary }}
          className="!mb-6 text-lg font-bold epilogue-font"
        >
          {translateStatistics[analysisLanguage] || translateStatistics.English}
          {' '}
          (
          {translateTotal[analysisLanguage] || translateTotal.English}
          )
        </h3>
        {data && !isLoading && (
          <div className={cn('hidden items-center gap-3', showSelect && 'flex')}>
            <p className="font-medium">{translateAddToReport[analysisLanguage] || translateAddToReport.English}</p>
            <div className="flex items-center justify-center w-5 h-5 border p-0.5 border-black rounded-full">
              <button
                onClick={() => {
                  onReportSave();
                }}
                style={{ backgroundColor: isStatisticChecked ? currentTheme?.primary : '#FFFFFF' }}
                type="button"
                className={cn('w-full h-full rounded-full')}
              >
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="grid grid-cols-4 gap-4 max1100:grid-cols-3 md800:grid-cols-2 min500:grid-cols-1">
        {/* completed interviews */}
        {
          isLoading
            ? <Skeleton className="w-full h-40 rounded-2xl" />
            : (
              <div
                style={{ backgroundColor: currentTheme?.primary }}
                className={cn(
                  'flex flex-col w-full h-40 py-5 px-7 justify-between text-white rounded-2xl',
                )}
              >
                <h4 className="font-medium">{translateCompletedInterviews[analysisLanguage] || translateCompletedInterviews.English}</h4>
                <span className="text-4xl font-semibold">
                  <p>
                    <CountUp end={data?.completed_interviews} />
                  </p>
                </span>
              </div>
            )
        }
        {/* started interviews */}
        {
          isLoading
            ? <Skeleton className="w-full h-40 rounded-2xl" />
            : (
              <div
                className={cn(
                  'flex flex-col w-full h-40 py-5 px-7 justify-between text-white rounded-2xl bg-slate-500',
                )}
              >
                <h4 className="font-medium">{translateInterviewsInProgress[analysisLanguage] || translateInterviewsInProgress.English}</h4>
                <span className="text-4xl font-semibold">
                  <p>
                    <CountUp end={data?.started_interviews} />
                  </p>
                </span>
              </div>
            )
        }
        {/* completion rate */}
        {
          isLoading
            ? <Skeleton className="w-full h-40 rounded-2xl" />
            : (
              <div
                className={cn(
                  'flex flex-col w-full h-40 py-5 px-7 justify-between text-white rounded-2xl bg-slate-500',
                )}
              >
                <h4 className="font-medium">{translateCompletionRate[analysisLanguage] || translateCompletionRate.English}</h4>
                <span className="text-4xl font-semibold">
                  <p>
                    <CountUp end={data?.completion_rate} />
                    {' '}
                    %
                  </p>
                </span>
              </div>
            )
        }
        {/* avg time */}
        {
          isLoading
            ? <Skeleton className="w-full h-40 rounded-2xl" />
            : (
              <div
                className={cn(
                  'flex flex-col w-full h-40 py-5 px-7 justify-between text-white rounded-2xl bg-slate-500',
                )}
              >
                <h4 className="font-medium">{translateAvgTimeToCompletion[analysisLanguage] || translateAvgTimeToCompletion.English}</h4>
                <span className="text-4xl font-semibold">
                  <p className="whitespace-nowrap">
                    {Number(formattedHours) > 0 ? <CountUp end={Number(formattedHours)} /> : 0}
                    h
                    :
                    {Number(formattedMinutes) ? <CountUp end={Number(formattedMinutes)} /> : 0}
                    m
                    :
                    {Number(formattedSeconds) < 10 && '0'}
                    <CountUp end={Number(formattedSeconds)} />
                    s
                  </p>
                </span>
              </div>
            )
        }
        {/* gender */}
        {(data?.gender_distribution && (data?.gender_distribution?.men !== 0 || data?.gender_distribution?.women !== 0)) && (singleProjectData?.panel_integration !== null
          && singleProjectData?.panel_integration !== EPanelIntegration.NONE) && (
          isLoading ? <Skeleton className="w-full h-40 rounded-2xl" /> : (
            <div
              className={cn(
                'flex flex-col w-full h-40 py-5 px-7 justify-between text-white rounded-2xl bg-slate-500',
              )}
            >
              <h4 className="font-medium">{lang.get('msg.Gender (Woman/Man)')}</h4>
              <span className="text-4xl font-semibold">
                <p>
                  <CountUp end={data?.gender_distribution?.women} />
                  {' '}
                  /
                  {' '}
                  <CountUp end={data?.gender_distribution?.men} />
                </p>
              </span>
            </div>
          )
        )}

        {/* age */}
        {(data?.average_age && data?.average_age !== 0) && (singleProjectData?.panel_integration !== null
          && singleProjectData?.panel_integration !== EPanelIntegration.NONE) && (
          isLoading ? <Skeleton className="w-full h-40 rounded-2xl" /> : (
            <div
              className={cn(
                'flex flex-col w-full h-40 py-5 px-7 justify-between text-white rounded-2xl bg-slate-500',
              )}
            >
              <h4 className="font-medium">{lang.get('msg.averageAge')}</h4>
              <span className="text-4xl font-semibold">
                <p>
                  <CountUp end={data?.average_age} />
                </p>
              </span>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default Statistics;
