import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IconInfoCircle, IconLoader2, IconTrash } from '@tabler/icons-react';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { MultiSelect } from 'react-multi-select-component';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import DatePickerWithRange from './DateRangeFilters';
import FilterKeysDropdown from './FilterKeysDropdown';
import FilterItemsDropdown from './FilterItemsDropdown';
import DateCalendarFilter from './DateCalendarFilter';
import { cn } from '@/lib/utils';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import { useGetUserData } from '@/reactQuery/get';
import { useMutateCreateFilter, useMutateEditFilter } from '@/reactQuery/post';
import { toast } from '@/components/ui/use-toast';
import useTranslation from '@/hooks/useTranslation';
import { EDateTypes, EFilterSelectionType, EFilterType } from '@/enums';
import validateFiltersFields from '@/utils/validateFilersFields';
import FilterTypeInfo from './FilterTypeInfo';

interface IModalProps {
  setOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  project_id: string | undefined;
  onReRun: (f_id: string | null) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editFilterData: any;
}

interface CustomField {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  type: EFilterType;
}

const types = ['text', 'numeric', 'date'];
const date_selection_data = ['Range', 'Before', 'After'];

function EditFilterModal({ setOpenEditModal, project_id, onReRun, editFilterData }: IModalProps) {
  const { data: user } = useGetUserData();
  const { lang } = useTranslation();
  const [filterName, setFilterName] = useState<string>('');
  const [filterItems, setFilterItems] = useState<FilterItem[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: { [field: string]: string } }>({});
  const [searchParams, setSearchParams] = useSearchParams();

  const filterId = searchParams.get('filter_id');
  const isEdit = searchParams.get('edit') === 'true';

  const addQueryParams = (params: Record<string, string>) => {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    setSearchParams(searchParams);
  };

  const removeQueryParams = (keys: string[]) => {
    keys.forEach((key) => {
      searchParams.delete(key);
    });
    setSearchParams(searchParams);
  };

  const { mutateAsync: mutateAsyncCreateFilter, isPending: isFilterCreationPending } = useMutateCreateFilter();
  const { mutateAsync: mutateAsyncEditFilter, isPending: isFilterEditingPending } = useMutateEditFilter();

  const { data: filterMetadata } = useQuery({
    queryFn: () => axiosInstanceAnalyze.get(`analyzer/filters/${project_id}/metadata`),
    queryKey: ['filter_metadata', { project_id }],
    select: (data) => data?.data,
  });

  const filterMetadataNew = filterMetadata?.map((item: { name: string }) => item.name);

  // Add a new filter item with the selected value
  const addNewFilterItem = (value: string, calculatedType: EFilterType) => {
    const newItem: FilterItem = {
      id: uuidv4(),
      itemValue: value,
      itemType: calculatedType,
      itemSelectionText: [],
      itemText: '',
      itemSelectionNumeric: { from: 0, to: 0 },
      itemTypeOfSelectionDate: EDateTypes.RANGE,
      itemSelectionDate: { from: undefined, to: undefined },
      itemSelectionDateBefore: { from: undefined, to: undefined },
      itemSelectionDateAfter: { from: undefined, to: undefined },
    };
    setFilterItems((prevItems) => [...prevItems, newItem]);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateFilterItem = (id: string, key: keyof FilterItem, value: any) => {
    setFilterItems((prevItems) => prevItems?.map((item) => (item?.id === id ? { ...item, [key]: value } : item)));
  };

  // Remove a filter item by id
  const removeFilterItem = (id: string) => {
    setFilterItems((prevItems) => prevItems.filter((item) => item?.id !== id));
  };

  // Get all used item values across filter items
  const allUsedValues = filterItems?.map((item) => item?.itemValue);

  // Filter available metadata and text selections
  const availableMetadata = filterMetadataNew?.filter((item: string) => !allUsedValues.includes(item));

  const onSubmit = async (edit?: boolean) => {
    if (!validateFiltersFields({ filterName, filterItems, setErrors })) return;

    const transformData = (items: FilterItem[]) => items.reduce((acc, item) => {
      const { itemValue } = item;

      // Handle text case
      if (item?.itemType === EFilterType.TEXT) {
        const values = item?.itemSelectionText?.map((option) => option.value);
        return {
          ...acc,
          [itemValue]: {
            value: values,
            type: EFilterSelectionType.TEXT,
          },
        };
      }

      // Handle numeric items
      if (item?.itemType === EFilterType.NUMERIC && itemValue) {
        return {
          ...acc,
          [itemValue]: {
            value: {
              from: Number(item?.itemSelectionNumeric.from),
              to: Number(item?.itemSelectionNumeric.to),
            },
            type: EFilterSelectionType.NUMBER_RANGE,
          },
        };
      }

      // Handle date items
      if (item?.itemType === EFilterType.DATE && itemValue) {
        const { from, to } = item.itemSelectionDate;
        const formattedFromDate = from ? dayjs(from).format('DD/MM/YYYY') : null;
        const formattedToDate = to ? dayjs(to).format('DD/MM/YYYY') : null;

        if (item?.itemTypeOfSelectionDate === EDateTypes.RANGE) {
          return {
            ...acc,
            [itemValue]: {
              value: {
                from: formattedFromDate,
                to: formattedToDate,
              },
              type: EFilterSelectionType.DATE,
            },
          };
        }

        if (item?.itemTypeOfSelectionDate === EDateTypes.BEFORE) {
          return {
            ...acc,
            [itemValue]: {
              value: {
                to: dayjs(String(item?.itemSelectionDateBefore)).format('DD/MM/YYYY'),
                from: '',
              },
              type: EFilterSelectionType.DATE,
            },
          };
        }

        if (item?.itemTypeOfSelectionDate === EDateTypes.AFTER) {
          return {
            ...acc,
            [itemValue]: {
              value: {
                from: dayjs(String(item?.itemSelectionDateAfter)).format('DD/MM/YYYY'),
                to: '',
              },
              type: EFilterSelectionType.DATE,
            },
          };
        }
      }

      return acc;
    }, {} as Record<string, unknown>);

    const transformedItems = transformData(filterItems);

    const { created_by, ...otherCustomFields } = transformedItems;

    const customFields = {
      ...otherCustomFields,
    };

    const dataToSend: FiltersDataToSendProps = {
      name: filterName,
      created_by: user?._id,
      customFields,
    };
    try {
      if (edit === true) {
        const res = await mutateAsyncEditFilter({ data: dataToSend, filter_id: editFilterData?._id, project_id });
        await onReRun(res?.data);
        setOpenEditModal(false);
        addQueryParams({ filter_id: res?.data });
        removeQueryParams(['edit']);
      } else {
        const res = await mutateAsyncCreateFilter({ data: dataToSend, project_id });
        await onReRun(res?.data);
        setOpenEditModal(false);
        addQueryParams({ filter_id: res?.data });
      }
    } catch {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  const exitModal = () => {
    setOpenEditModal(false);
    setFilterItems([]);

    if (isEdit) {
      removeQueryParams(['edit']);
    }
  };

  const handleAddFilterItem = (value: string) => {
    const rowMetadata = filterMetadata?.find((item: { name: string }) => item?.name === value)?.values;

    if (!Array.isArray(rowMetadata)) {
      addNewFilterItem(value, EFilterType.TEXT);
      return;
    }

    const parseCustomDate = (dateString: string): Date | null => {
      const normalizedDateString = dateString.replace(/\.$/, '').replace(/[./]/g, '-');

      const datePattern = /^(\d{2})-(\d{2})-(\d{4})$/;
      const match = normalizedDateString.match(datePattern);

      if (match) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, day, month, year] = match.map((part) => parseInt(part, 10));
        const validDate = new Date(`${year}-${month}-${day}`);

        return Number.isNaN(validDate.getTime()) ? null : validDate;
      }

      return null;
    };

    const isNumeric = (str: string): boolean => {
      const num = Number(str);
      return !Number.isNaN(num) && !/[^0-9.]/.test(str);
    };

    const isAllDates = rowMetadata.every((item) => {
      if (typeof item === 'string') {
        const date = parseCustomDate(item);
        return date !== null;
      }
      return false;
    });

    if (isAllDates) {
      addNewFilterItem(value, EFilterType.DATE);
      return;
    }

    if (value === 'numberRange') {
      addNewFilterItem(value, EFilterType.NUMERIC);
      return;
    }

    const isAllNumeric = rowMetadata.every((item) => {
      if (typeof item === 'string') {
        return isNumeric(item);
      }
      return typeof item === 'number';
    });

    if (isAllNumeric) {
      addNewFilterItem(value, EFilterType.NUMERIC);
      return;
    }

    if (rowMetadata.every((item) => typeof item === 'string')) {
      addNewFilterItem(value, EFilterType.TEXT);
      return;
    }

    addNewFilterItem(value, EFilterType.TEXT);
  };

  useEffect(() => {
    if (editFilterData?.customFields && isEdit && !!filterId) {
      const transformedItems = Object.entries(editFilterData?.customFields)?.map(
        ([key, field]) => {
          const { value, type } = field as CustomField;
          const numberRange = 'numberRange' as EFilterType;

          const item: FilterItem = {
            id: uuidv4(),
            itemValue: key,
            itemType: type === numberRange ? EFilterType.NUMERIC : type,
            itemSelectionText: [],
            itemText: '',
            itemSelectionNumeric: { from: 0, to: 0 },
            itemTypeOfSelectionDate: EDateTypes.RANGE,
            itemSelectionDate: { from: undefined, to: undefined },
            itemSelectionDateBefore: { from: undefined, to: undefined },
            itemSelectionDateAfter: { from: undefined, to: undefined },
          };

          if (type === EFilterType.TEXT) {
            item.itemSelectionText = (value).map((v: string) => ({
              label: v,
              value: v,
            }));
          } else if (type === EFilterType.NUMBER_RANGE) {
            const { from, to } = value as { from: number; to: number };
            item.itemSelectionNumeric = { from, to };
          } else if (type === EFilterType.DATE) {
            const { from, to } = value as { from?: string; to?: string };

            const parsedFrom = from ?? '';
            const parsedTo = to ?? '';

            const transformToDate = (dateString: string) => {
              const [day, month, year] = dateString.split('/');

              return new Date(Number(year), Number(month) - 1, Number(day));
            };

            item.itemSelectionDate = { from: transformToDate(parsedFrom), to: transformToDate(parsedTo) };
            item.itemSelectionDateBefore = { to: transformToDate(parsedTo), from: undefined };
            item.itemSelectionDateAfter = { from: transformToDate(parsedFrom), to: undefined };

            // eslint-disable-next-line no-nested-ternary
            item.itemTypeOfSelectionDate = from && to
              ? EDateTypes.RANGE
              : from
                ? EDateTypes.AFTER
                : EDateTypes.BEFORE;
          }

          return item;
        },
      );
      if (editFilterData?.name) {
        setFilterName(editFilterData?.name);
      }
      setFilterItems(transformedItems);
    }
  }, [editFilterData, isEdit, filterId]);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[99999] w-full h-full bg-black bg-opacity-60">
      <div className="bg-white flex flex-col mx-auto justify-between w-[90%] h-[90%] rounded-lg p-6">
        <form className="relative z-10 flex flex-col w-full h-full">

          {/* Filter Name */}
          <div className="flex items-center justify-between w-full">
            <label className="flex flex-col w-full gap-1 text-sm font-semibold max-w-80">
              <p>Filter name:</p>
              <Input
                defaultValue={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                placeholder="Enter your filter name"
                className="bg-white shadow-none"
                hasError={!!errors?.filterName?.filterName}
              />
              {errors?.filterName && <p className="text-xs text-red-600 whitespace-nowrap">{errors?.filterName?.filterName}</p>}
            </label>
          </div>

          {/* Dropdown for adding new filter item */}
          <div className="w-full mt-4">
            <div className="max-w-64">
              <FilterKeysDropdown
                value=""
                onChange={(value) => handleAddFilterItem(value)}
                data={availableMetadata}
                isDisabled={availableMetadata?.length === 0}
                placeholder="Add filter value"
              />
            </div>
          </div>

          {/* Dynamically Render Filter Grids */}
          <div className="mt-5 space-y-3 pr-2 max-h-[55vh] h-full overflow-y-auto">
            {filterItems?.length !== 0 ? filterItems?.map((item) => (
              <div
                key={item?.id}
                style={{ gridTemplateColumns: '1fr 1fr 1fr max(40px, 0px)' }}
                className="grid items-center w-full grid-cols-5 gap-4 p-5 pb-3 bg-gray-100 rounded-lg max1200:grid-cols-2"
              >

                {/* Value Dropdown (Pre-selected with the selected FilterKeysDropdown value) */}
                <label className="flex flex-col w-full gap-1 mb-4 font-medium">
                  <p className="text-sm">Value:</p>
                  <FilterItemsDropdown
                    value={item?.itemValue}
                    onChange={(value) => updateFilterItem(item?.id, 'itemValue', value)}
                    data={availableMetadata}
                    isDisabled={availableMetadata?.length === 0}
                    placeholder="Choose value"
                  />
                </label>

                {/* Type Dropdown */}
                <div className="flex flex-col w-full gap-1 mb-4 font-medium">
                  <div className="flex items-center w-full justify-between">
                    <p className="text-sm">Type:</p>
                    <FilterTypeInfo />
                  </div>
                  <FilterItemsDropdown
                    value={item?.itemType}
                    onChange={(value) => updateFilterItem(item?.id, 'itemType', value)}
                    data={types}
                    isDisabled={false}
                    placeholder="Choose type"
                  />
                </div>

                {/* Dynamic Selection Field Based on Type */}
                <label className="flex flex-col w-full gap-1 font-medium">
                  <div className="w-full">
                    <p className="mb-1 text-sm">Selection:</p>
                    {item?.itemType === EFilterType.TEXT && (
                      <div className="flex flex-col gap-1">
                        <MultiSelect
                          options={
                            filterMetadata?.find((meta: { name: string }) => meta.name === item?.itemValue)?.values.map((val: { label: string, value: string }) => ({
                              label: String(val),
                              value: String(val),
                            })) || []
                          }
                          value={item.itemSelectionText.map((val) => val)}
                          onChange={(selected: string) => {
                            updateFilterItem(item.id, 'itemSelectionText', selected);
                          }}
                          labelledBy="Select"
                          className={cn(!!errors?.[item?.id]?.itemSelectionText && '!border-red-600 border rounded-md', 'text-sm')}
                        />
                        <p className="h-3 text-xs text-red-600 whitespace-nowrap">{errors?.[item?.id]?.itemSelectionText}</p>
                      </div>
                    )}
                    {item?.itemType === EFilterType.NUMERIC && (
                      <div className="flex flex-col gap-1">
                        <div className="flex items-center justify-between w-full gap-3">
                          <div className="relative flex flex-col w-full">
                            <Input
                              placeholder="Min"
                              type="number"
                              className="w-full bg-white shadow-none"
                              value={item?.itemSelectionNumeric.from}
                              onChange={(e) => updateFilterItem(
                                item?.id,
                                'itemSelectionNumeric',
                                { ...item?.itemSelectionNumeric, from: Number(e.target.value) },
                              )}
                              hasError={!!errors?.[item?.id]?.itemSelectionNumeric}
                            />
                          </div>
                          <p className="text-xs">between</p>
                          <div className="relative flex flex-col w-full">
                            <Input
                              placeholder="Max"
                              type="number"
                              className="w-full bg-white shadow-none"
                              value={item?.itemSelectionNumeric.to}
                              onChange={(e) => updateFilterItem(
                                item?.id,
                                'itemSelectionNumeric',
                                { ...item?.itemSelectionNumeric, to: Number(e.target.value) },
                              )}
                              hasError={!!errors?.[item?.id]?.itemSelectionNumeric}
                            />
                          </div>
                        </div>
                        <p className="h-3 text-xs text-red-600 whitespace-nowrap">{errors?.[item?.id]?.itemSelectionNumeric}</p>
                      </div>
                    )}
                    {item?.itemType === EFilterType.DATE && (
                      <div className="flex items-center w-full gap-3">
                        <div className="w-full mb-4 min-w-28">
                          <FilterItemsDropdown
                            value={item?.itemTypeOfSelectionDate}
                            onChange={(value) => {
                              updateFilterItem(item?.id, 'itemTypeOfSelectionDate', value);
                              updateFilterItem(item?.id, 'itemSelectionDate', '');
                              updateFilterItem(item?.id, 'itemSelectionDateBefore', '');
                              updateFilterItem(item?.id, 'itemSelectionDateAfter', '');
                            }}
                            data={date_selection_data}
                            isDisabled={false}
                            placeholder="Range"
                          />
                        </div>
                        {item?.itemTypeOfSelectionDate === EDateTypes.RANGE
                          && (
                            <div className="flex flex-col gap-1">
                              <DatePickerWithRange
                                onDateChange={(date) => updateFilterItem(item?.id, 'itemSelectionDate', date)}
                                defaultFrom={item?.itemSelectionDate?.from}
                                defaultTo={item?.itemSelectionDate?.to}
                                hasError={!!errors?.[item?.id]?.itemSelectionDate}
                              />
                              <p className="h-3 text-xs text-red-600 whitespace-nowrap">{errors?.[item?.id]?.itemSelectionDate}</p>
                            </div>
                          )}
                        {item?.itemTypeOfSelectionDate === EDateTypes.BEFORE
                          && (
                            <div className="flex flex-col gap-1">
                              <DateCalendarFilter
                                hasError={!!errors?.[item?.id]?.itemSelectionDateBefore}
                                onSelect={(date) => updateFilterItem(item?.id, 'itemSelectionDateBefore', date)}
                                defaultValue={item?.itemSelectionDateBefore?.to as ((string | number | readonly string[]) & Date) | undefined}
                              />
                              <p className="h-3 text-xs text-red-600 whitespace-nowrap">{errors?.[item?.id]?.itemSelectionDateBefore}</p>

                            </div>
                          )}
                        {item?.itemTypeOfSelectionDate === EDateTypes.AFTER
                          && (
                            <div className="flex flex-col gap-1">
                              <DateCalendarFilter
                                hasError={!!errors?.[item?.id]?.itemSelectionDateAfter}
                                defaultValue={item?.itemSelectionDateAfter?.from as ((string | number | readonly string[]) & Date) | undefined}
                                onSelect={(date) => updateFilterItem(item?.id, 'itemSelectionDateAfter', date)}
                              />
                              <p className="h-3 text-xs text-red-600 whitespace-nowrap">{errors?.[item?.id]?.itemSelectionDateAfter}</p>
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                </label>

                {/* Delete Button Column */}
                <div className="flex items-center justify-end w-full">
                  <button
                    className="self-end p-1 mt-5 text-white bg-red-600 rounded-full hover:bg-red-700"
                    type="button"
                    onClick={() => removeFilterItem(item?.id)}
                  >
                    <IconTrash size={18} />
                  </button>
                </div>
              </div>
            )) : (
              <p className="flex items-center gap-1 mt-4 text-sm font-medium">
                <IconInfoCircle size={15} />
                No filter selected, please pick a value to edit.
              </p>
            )}
          </div>

        </form>

        {/* Modal Action Buttons */}
        <div className="flex items-center self-end gap-2">
          <Button variant="outline" onClick={exitModal}>
            {lang.get('msg.cancel')}
          </Button>
          {isEdit ? (
            <Button
              disabled={isFilterEditingPending || filterItems?.length === 0}
              className="flex items-center gap-1"
              variant="default"
              onClick={() => onSubmit(true)}
            >
              {isFilterEditingPending && <IconLoader2 className="animate-spin" size={18} />}
              {lang.get('msg.saveFilter')}
            </Button>
          ) : (
            <Button
              disabled={isFilterCreationPending || filterItems?.length === 0}
              className="flex items-center gap-1"
              variant="default"
              onClick={() => onSubmit()}
            >
              {isFilterCreationPending && <IconLoader2 className="animate-spin" size={18} />}
              {lang.get('msg.create')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditFilterModal;
