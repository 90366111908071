import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-react';
import { Table } from '@tanstack/react-table';
import { Button } from '../ui/button';

interface ITable {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: Table<any>;
}

function DataTablePagination({ table }: ITable) {
  return (
    <div className="flex items-center justify-end py-4">
      <div>
        {table?.getFilteredRowModel()?.rows?.length >= 20
          && (
            <div className="flex items-center space-x-2">
              <Button
                className="hidden p-0 size-6 lg:flex"
                disabled={!table.getCanPreviousPage()}
                onClick={() => {
                  table.setPageIndex(0);
                }}
                variant="outline"
              >
                <span className="sr-only">Go to first page</span>
                <ChevronsLeft className="size-4" />
              </Button>
              <Button
                className="p-0 size-6"
                disabled={!table.getCanPreviousPage()}
                onClick={() => {
                  table.previousPage();
                }}
                variant="outline"
              >
                <span className="sr-only">Go to previous page</span>
                <ChevronLeft className="size-4" />
              </Button>
              <div className="flex w-[80px] items-center justify-center text-xs font-medium">
                Page
                {' '}
                {table.getState().pagination.pageIndex + 1}
                {' '}
                of
                {' '}
                {table.getPageCount()}
              </div>
              <Button
                className="p-0 size-6"
                disabled={!table.getCanNextPage()}
                onClick={() => {
                  table.nextPage();
                }}
                variant="outline"
              >
                <span className="sr-only">Go to next page</span>
                <ChevronRight className="size-4" />
              </Button>
              <Button
                className="hidden p-0 size-6 lg:flex"
                disabled={!table.getCanNextPage()}
                onClick={() => {
                  table.setPageIndex(table.getPageCount() - 1);
                }}
                variant="outline"
              >
                <span className="sr-only">Go to last page</span>
                <ChevronsRight className="size-4" />
              </Button>
            </div>
          )}
      </div>
    </div>
  );
}

export default DataTablePagination;
