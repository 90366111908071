/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconLoader2 } from '@tabler/icons-react';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MultiSelect } from 'react-multi-select-component';
import { Button } from '@/components/ui/button';
import { ECategoryMode } from '@/enums';
import useTranslation from '@/hooks/useTranslation';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import { cn } from '@/lib/utils';
import useCategoryStore from '@/store/CategoryStore';
import useResultTabStore from '@/store/ResultsTabStore';
import TooltipWrapper from '../tooltip/TooltipWrapper';
import MultipleCategoryCell from './MultipleCategoryCell';
import SingleCategoryCell from './SingleCategoryCell';
import getTextColor from '@/utils/getTextColorContrast';

interface ICategory {
  label: string;
  color: string;
  prompt: string;
}

export interface IProject {
  gender?: string;
  age?: number;
  started: string;
  questions: string;
  conversation_id: string;
  category: ICategory | ICategory[];
}

export const QuestionAnswersColumns = (
  isPanel: boolean,
  categoryDataSingle: any,
  questionID: string | undefined,
  categoryDataMultiple: any,
  isCategoryDataExists: boolean,
  areAllCategoriesZeroSingle: boolean,
  areAllCategoriesZeroMulti: boolean,
  transformedCategories: any,
  setSelectedCategories: React.Dispatch<React.SetStateAction<{
    label: string;
    value: string;
  }[]>>,
  selectedCategories: {
    label: string;
    value: string;
  }[],
  project_id?: string,
  workspace_id?: string,
) => {
  const { lang } = useTranslation();
  const setActiveTab = useResultTabStore((state) => state.setActiveTab);
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryType = useCategoryStore((state) => state.categoryType);
  const { isUserViewer } = useWorkspaceRoles(workspace_id);
  const [loadingRowId, setLoadingRowId] = useState<string | null>(null);

  const handleRowLoading = (rowId: string) => {
    setLoadingRowId(rowId);
  };

  const stopRowLoading = () => {
    setLoadingRowId(null);
  };

  const addQueryParams = (params: Record<string, string>) => {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    setSearchParams(searchParams);
  };

  const showConversation = (conversation_id: string) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    addQueryParams({ selected_transcript: conversation_id });
    setActiveTab('transcripts_tab');
  };

  let columns: ColumnDef<IProject>[] = [
    {
      accessorKey: 'category',
      header: ({ column }) => (
        <div className="flex flex-col mb-2.5">
          <Button
            className="pl-0.5 w-full max-w-48"
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            {lang.get('msg.category')}
          </Button>
          <MultiSelect
            options={transformedCategories}
            value={selectedCategories}
            onChange={setSelectedCategories}
            labelledBy="Select"
            className="w-56"
          />
        </div>
      ),
      cell: ({ row }) => {
        if (!categoryType) return '/';
        const rowId = row.id;

        function normalizeCategory(category: ICategory | ICategory[]): ICategory {
          return Array.isArray(category) ? category[0] : category;
        }

        if (loadingRowId === rowId) {
          <IconLoader2 className="m-auto text-primary animate-spin" size={24} />;
        }

        if (categoryType === ECategoryMode.SINGLE) {
          const rawCategory = row.original?.category;
          const category = normalizeCategory(rawCategory);

          return (
            <div className="w-full h-full pb-5 min-w-[300px]">
              {!isUserViewer && isCategoryDataExists && !areAllCategoriesZeroSingle && !!category && (
                <SingleCategoryCell
                  categories={category}
                  rowId={rowId}
                  categoryData={categoryDataSingle}
                  questionID={questionID}
                  conversation_id={row?.original?.conversation_id}
                  project_id={project_id}
                  workspace_id={workspace_id}
                  onLoadingStart={() => handleRowLoading(rowId)}
                  onLoadingEnd={stopRowLoading}
                />
              )}

              <TooltipWrapper
                text={category?.prompt}
                className="relative mb-1 max-w-[280px] text-center flex ml-3"
              >
                <div
                  style={{
                    backgroundColor: category?.color,
                    color: getTextColor(category?.color || ''),
                  }}
                  className="flex items-center w-fit justify-center px-4 py-1.5 mb-2 rounded-full"
                >
                  <p className="flex text-[15px] items-center justify-center w-fit font-semibold">
                    {category?.label ?? null}
                  </p>
                </div>
              </TooltipWrapper>
            </div>
          );
        }

        if (categoryType === ECategoryMode.MULTIPLE) {
          const categories = row.original?.category as ICategory[];
          const categoriesAreNull = categories?.some((item) => item === null);

          return (
            <div className="relative min-w-[300px]">
              {!isUserViewer && isCategoryDataExists && !areAllCategoriesZeroMulti
                && categories?.length !== 0 && !categoriesAreNull && (
                  <MultipleCategoryCell
                    categories={categories}
                    categoryData={categoryDataMultiple}
                    questionID={questionID}
                    conversation_id={row?.original?.conversation_id}
                    project_id={project_id}
                    workspace_id={workspace_id}
                    onLoadingStart={() => handleRowLoading(rowId)}
                    onLoadingEnd={stopRowLoading}
                  />
              )}
              {React.Children.toArray(categories?.map((item) => (
                <div className="relative w-full h-full">
                  <TooltipWrapper
                    key={item?.label}
                    text={item?.prompt}
                    className="relative mb-1 max-w-[280px] text-center flex ml-3"
                  >
                    <div
                      style={{
                        backgroundColor: item?.color,
                        color: getTextColor(item?.color || ''),
                      }}
                      className="flex items-center justify-center w-fit px-4 py-1.5 mb-2 rounded-full"
                    >
                      <p className="flex items-center text-[15px] justify-center w-fit font-semibold">
                        {item?.label ?? null}
                      </p>
                    </div>
                  </TooltipWrapper>
                </div>
              )))}
            </div>
          );
        }

        return '/';
      },
    },
    {
      accessorKey: 'questions',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0.5 w-full max-w-48"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {lang.get('msg.transcripts')}
        </Button>
      ),
      cell: ({ row }) => (
        <div className="flex flex-col items-center justify-between p-0 transition-all ease-in-out">
          <div className="flex flex-col w-full gap-5 min-w-[500px]">
            <div className="flex flex-col w-full max-w-full gap-5">
              <div className="flex flex-col gap-1 font-medium text-dark-text/80">
                {React.Children.toArray(
                  row?.original?.questions
                    .split('\n')
                    ?.map((line) => {
                      const parts = line.split(/(Assistant:|User:)/);
                      return (
                        <p className="font-medium text-dark-text/80">
                          {parts?.map((part, index) => {
                            if (index % 2 === 1) {
                              return (
                                <span
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={index}
                                  className={cn(
                                    'font-bold',
                                    part.includes('Assistant') ? 'text-purple-500' : 'text-primary',
                                  )}
                                >
                                  {`${parts[index - 1]}${part}`}
                                </span>
                              );
                            }
                            return part;
                          })}
                          <br />
                        </p>
                      );
                    }),
                )}
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 'actions',
      header: () => (
        <Button
          variant="ghost"
          className="pl-0.5 w-full max-w-[210px] mb-[50px]"
        >
          Conversations
        </Button>
      ),
      maxSize: 210,
      enableHiding: false,
      cell: ({ row }) => (
        <div className="flex justify-center w-full min-w-[210px]">
          <Button
            variant="default"
            onClick={() => showConversation(row?.original?.conversation_id)}
          >
            Conversation
          </Button>
        </div>
      ),
    },
    {
      accessorKey: 'started',
      enableHiding: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0.5 w-full max-w-48"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {lang.get('msg.started')}
        </Button>
      ),
      cell: ({ row }) => (
        <div>
          <p className="whitespace-nowrap">
            {
              dayjs(row.getValue('started')).format('DD/MM/YYYY - HH:mm')
            }
          </p>
        </div>
      ),
    },
  ];

  if (isPanel) {
    columns = [
      ...columns,
      {
        accessorKey: 'gender',
        enableHiding: true,
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="pl-0.5 w-full max-w-48"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            {lang.get('msg.gender')}
          </Button>
        ),
        cell: ({ row }) => (
          <div>
            <p className="mr-2 whitespace-nowrap">{row?.getValue('gender') || '/'}</p>
          </div>
        ),
      },
      {
        accessorKey: 'age',
        enableHiding: true,
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="pl-0.5 w-full"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            {lang.get('msg.age')}
          </Button>
        ),
        cell: ({ row }) => (
          <div>
            <p className="whitespace-nowrap">{row.getValue('age') || '/'}</p>
          </div>
        ),
      },
    ];
  }

  return columns;
};
