import { useRef, useState } from 'react';
import useOnClickOutside from 'chat-ui/src/hooks/useClickoutside';
import { EFilterSelectionType } from '@/enums';
import { cn } from '@/lib/utils';

const questionDescriptions: Record<EFilterSelectionType, { title: string; description: string }> = {
  [EFilterSelectionType.TEXT]: {
    title: 'When yo use text?',
    description: 'Use text for metadata that includes categories or descriptive values. Examples include gender, diet type, or any data represented as free-form text.',
  },
  [EFilterSelectionType.DATE]: {
    title: 'When to use date?',
    description: 'Use date for metadata tied to specific points in time. Examples include project deadlines, scheduling dates, or any other data that represents a date value.',
  },
  [EFilterSelectionType.NUMBER_RANGE]: {
    title: 'When to use numeric?',
    description: 'Use numeric for metadata involving numbers or measurable values. Examples include scores, quantities, or other numerical data.',
  },
};

const answerRulesList = [
  {
    id: 1,
    title: EFilterSelectionType.TEXT,
  },
  {
    id: 2,
    title: EFilterSelectionType.DATE,
  },
  {
    id: 3,
    title: EFilterSelectionType.NUMBER_RANGE,
  },
];

function Description({ type }: { type: EFilterSelectionType }) {
  return (
    <div className="flex flex-col w-full gap-3">
      <h4 className="font-semibold">{questionDescriptions[type].title}</h4>
      <p className="text-xs font-medium">{questionDescriptions[type].description}</p>
    </div>
  );
}

function FilterTypeInfo() {
  const popupRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [showTypeDescriptionFor, setShowTypeDescriptionFor] = useState(EFilterSelectionType.TEXT);

  const toggleInfo = () => setIsInfoOpen((prev) => !prev);

  useOnClickOutside(popupRef, (event) => {
    // Ensure the click is outside both the button and the modal content
    if (
      popupRef.current
      && !popupRef.current.contains(event.target as Node)
      && buttonRef.current
      && !buttonRef.current.contains(event.target as Node)
    ) {
      setIsInfoOpen(false);
    }
  });

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        onClick={toggleInfo}
        type="button"
        className="w-5 h-5 text-[13px] text-blue-600 bg-blue-200 rounded-full"
      >
        ?
      </button>

      {isInfoOpen && (
        <div
          ref={popupRef}
          className="flex absolute z-100 h-[250px] right-[430px] top-[25px] w-full"
        >
          <div className="flex flex-col p-6 bg-white min-w-[200px] border-y-2 border-l-2 border-black/20">
            <p className="font-semibold text-gray-400 uppercase whitespace-nowrap">Type:</p>
            <div className="flex flex-col items-start w-full gap-5 mt-5 font-semibold text-gray-400 whitespace-nowrap">
              {answerRulesList?.map((item) => (
                <button
                  key={item.id}
                  onClick={() => setShowTypeDescriptionFor(item.title)}
                  className={cn(
                    'flex items-center capitalize gap-2 transition-all hover:text-black',
                    showTypeDescriptionFor === item.title && 'text-black',
                  )}
                  type="button"
                >
                  {item.title === EFilterSelectionType.NUMBER_RANGE ? 'Numeric' : item.title}
                </button>
              ))}
            </div>
          </div>
          <div className="flex flex-col p-6 bg-green-100 min-w-[250px] border-y-2 border-r-2 border-black/20">
            <Description type={showTypeDescriptionFor} />
          </div>
        </div>
      )}
    </div>
  );
}

export default FilterTypeInfo;
