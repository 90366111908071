import { useRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import videojs from 'video.js';
import VideoPlayer from './VideoJsPlayer';

interface TMessageAttachment {
  url: string;
  transcript: string;
}

function VideoMessage({ url, transcript }: TMessageAttachment) {
  const playerRef = useRef<ReturnType<typeof videojs> | null>(null);

  const videoJsOptions: IVideoOptionsProps = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    playsinline: true,
    sources: [{
      src: url,
      type: 'video/mp4',
    }],
  };

  const handlePlayerReady = (player: ReturnType<typeof videojs>) => {
    playerRef.current = player;

    player.on('waiting', () => {
      // videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      // videojs.log('player will dispose');
    });
  };

  return (
    <div className="flex items-center flex-col max-w-full w-[40rem]">
      <VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} />
      {transcript?.length as number > 1
        && (
          <p className="self-start w-full p-1 pt-1 mt-3 border-t border-white text-start">
            &quot;
            {transcript}
            {' '}
            &quot;
          </p>
        )}
    </div>
  );
}

export default VideoMessage;
