import React, { useState } from 'react';
import { IconChevronDown } from '@tabler/icons-react';

interface AccordionItemProps {
  title: string;
  body: string;
  index: number;
}

interface IAccordionData {
  data: AccordionItemProps[];
}

function AccordionItem({ title, body, index }: AccordionItemProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="py-2">
      <h2>
        <button
          type="button"
          className="flex items-center justify-between w-full py-2 font-semibold text-left"
          onClick={() => setIsExpanded(!isExpanded)}
          aria-expanded={isExpanded}
          aria-controls={`faqs-text-${title}`}
        >
          <span className="flex items-center gap-2">
            {(index !== undefined && index !== null) && (
            <span>
              {index + 1}
              )
            </span>
            )}
            {title}
          </span>
          <IconChevronDown
            className={`shrink-0 ml-8 transition-transform duration-200 ${isExpanded ? 'rotate-180' : ''
            }`}
            size={20}
          />
        </button>
      </h2>
      <div
        id={`item-text-${title}`}
        role="region"
        aria-labelledby={`item-title-${title}`}
        className={`grid text-base overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
        }`}
      >
        <div className="overflow-hidden">
          <p className="pb-3">{body}</p>
        </div>
      </div>
    </div>
  );
}

function QuestionAnswerAccordion({ data }: IAccordionData) {
  return (
    <div className="relative w-full">
      <main className="relative flex flex-col justify-center overflow-hidden h-fit">
        <div className="w-full mx-auto">
          <div className="divide-y divide-slate-200">
            {React.Children.toArray(data?.map((item) => (
              <AccordionItem
                title={item.title}
                body={item.body}
                index={item.index}
              />
            )))}
          </div>
        </div>
      </main>
    </div>
  );
}

export default QuestionAnswerAccordion;
