'use client';

import * as React from 'react';
import { format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { DateRange } from 'react-day-picker';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';

interface DatePickerWithRangeProps extends React.HTMLAttributes<HTMLDivElement> {
  onDateChange: (range: DateRange | undefined) => void;
  hasError?: boolean;
  defaultFrom?: Date | undefined;
  defaultTo?: Date | undefined;
}

function DatePickerWithRange({
  className,
  onDateChange,
  hasError,
  defaultFrom = undefined,
  defaultTo = undefined,
}: DatePickerWithRangeProps): JSX.Element {
  const [date, setDate] = React.useState<DateRange | undefined>({
    from: defaultFrom || undefined,
    to: defaultTo || undefined,
  });

  const handleDateChange = (selectedDate: DateRange | undefined) => {
    setDate(selectedDate);
    onDateChange(selectedDate);
  };

  React.useEffect(() => {
    if (defaultFrom || defaultTo) {
      setDate({ from: defaultFrom, to: defaultTo });
    }
  }, [defaultFrom, defaultTo]);

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant="outline"
            className={cn(
              'w-full justify-start text-left font-normal min-w-[230px]',
              !date && 'text-muted-foreground',
              hasError && 'border-red-600',
            )}
          >
            <CalendarIcon className="mr-2 min-w-4 min-h-4" />
            {/* eslint-disable-next-line no-nested-ternary */}
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, 'dd/MM/yyyy')}
                  {' '}
                  -
                  {format(date.to, 'dd/MM/yyyy')}
                </>
              ) : (
                format(date.from, 'dd/MM/yyyy')
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0 z-[99999]" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={handleDateChange}
            numberOfMonths={1}
            className="focus:text-white"
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default DatePickerWithRange;
