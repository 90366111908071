import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useContext, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Input } from '@/components/ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { LangContext } from '@/context/LangProvider';
import { ProjectBrandingColumns } from './ProjectBrandingColumns';
import CreateProjectBrandingModal from '../Modals/CreateProjectBrandingModal';
import axiosInstance from '@/axios/axiosInstance';
import FullPageLoader from '../atoms/Loader/FullPageLoader';
import DataTablePagination from '../paginations/DataTablePagination';

export default function ProjectBrandingTable() {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const { lang } = useContext(LangContext);
  const [openModal, setOpenModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { organization_id } = useParams();

  const { data: allBrandingsList, isLoading: isAllBrandingsListLoading } = useQuery({
    queryFn: () => axiosInstance.get('/themes/organization/all'),
    queryKey: ['all_brandings_data', { organization_id }],
    enabled: !!organization_id,
    select: (data) => data?.data,
  });

  const addQueryParams = (params: Record<string, string>) => {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    setSearchParams(searchParams);
  };

  const handleOpenEditModal = (id: string) => {
    addQueryParams({ mode: 'edit', id });
    setOpenModal(true);
  };

  const columns = ProjectBrandingColumns(handleOpenEditModal);

  const table = useReactTable({
    data: allBrandingsList,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
  });

  if (isAllBrandingsListLoading || !allBrandingsList) return <FullPageLoader />;

  return (
    <div className="w-full">
      <div className="flex flex-wrap items-center justify-end w-full gap-4 pt-4 pb-2">
        <div className="flex items-center w-full gap-4 mb-2">
          {/* button for creating new branding */}
          <CreateProjectBrandingModal openModal={openModal} setOpenModal={setOpenModal} />

          <Input
            placeholder="Filter branding by name"
            value={(table.getColumn('name')?.getFilterValue() as string) ?? ''}
            onChange={(event) => table.getColumn('name')?.setFilterValue(event.target.value)}
            className="w-full max-w-[400px]"
          />
        </div>
      </div>
      <div className="border rounded-md">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  style={{ backgroundColor: row?.original?.name === 'Default' ? '#e5e7ebb9' : '#FFFFFF',
                  }}
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell className="last:justify-end last:flex" key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  {lang.get('msg.noResults')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
}
