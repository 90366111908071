import { Fragment, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { IconChevronDown, IconCheck, IconSquareRoundedPlus, IconTrash, IconEdit, IconLoader2 } from '@tabler/icons-react';
import { Listbox, Transition } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@/components/ui/button';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import EditFilterModal from '@/components/Modals/FilterModal/EditFilterModal';
import { useMutateDeleteFilter } from '@/reactQuery/post';
import { toast } from '@/components/ui/use-toast';

interface IFiltersSelectProps {
  onReRun: () => Promise<void>;
}

function FiltersSelect({ onReRun }: IFiltersSelectProps) {
  const { project_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const filter_id = searchParams.get('filter_id');
  const isEdit = searchParams.get('edit') === 'true';
  const [selectedFilter, setSelectedFilter] = useState(filter_id || '');
  const [openEditModal, setOpenEditModal] = useState(false);

  const { data: filtersListData } = useQuery({
    queryFn: () => axiosInstanceAnalyze.get(`/analyzer/filters/${project_id}/get`),
    queryKey: ['all_project_filters'],
    select: (data) => data?.data,
  });

  const editFilterData = filtersListData?.filters.find((item: { _id: string }) => item?._id === filter_id);

  const removeSelectedFilter = () => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete('filter_id');
    setSearchParams(newSearchParams);
  };

  const { mutateAsync: mutateAsyncDeleteFilter, isPending: isFilterDeletionPending } = useMutateDeleteFilter();

  const handleDeleteFIlter = async (f_id: string | null) => {
    try {
      await mutateAsyncDeleteFilter({
        project_id,
        filter_id: f_id,
      });

      if (f_id === filter_id) {
        removeSelectedFilter();
      }
      toast({ description: 'Filter successfully deleted' });
    } catch {
      toast({ description: 'Error, please try again.', variant: 'destructive' });
    }
  };

  const addQueryParams = (params: Record<string, string>) => {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    setSearchParams(searchParams);
  };

  const handleEditFilter = (id: string) => {
    addQueryParams({ filter_id: id, edit: 'true' });
    setOpenEditModal(true);
  };

  const handleSettingFilter = (id: string) => {
    if (id) {
      addQueryParams({ filter_id: id });
    } else {
      removeSelectedFilter();
    }
    setSelectedFilter(id);
  };
  const getFilterNameById = (id: string | null) => {
    if (filtersListData && id) {
      const filter = filtersListData?.filters?.find((f: { _id: string }) => f._id === id);
      return filter ? filter.name : '';
    }
    return '';
  };

  useEffect(() => {
    // Update selectedFilter when filter_id from URL changes
    if (filter_id) {
      setSelectedFilter(filter_id);
    }
  }, [filter_id]);

  useEffect(() => {
    if (isEdit && !!filter_id) {
      setOpenEditModal(true);
    }
  }, [isEdit, filter_id]);

  return (
    <>
      <Listbox value={selectedFilter || ''} onChange={(e) => handleSettingFilter(e)}>
        <div className="relative mt-1">
          <Listbox.Button className="flex relative truncate cursor-pointer w-52 h-8 items-center justify-between rounded-md border-2 shadow
       border-neutral-200 bg-gray-50 text-black font-medium px-3 py-2 text-sm ring-offset-white placeholder:text-neutral-500
        focus:outline-none focus:ring-1 disabled:cursor-not-allowed
         disabled:opacity-50 [&>span]:line-clamp-1 dark:border-neutral-800 dark:bg-neutral-950
           dark:placeholder:text-neutral-400 dark:focus:ring-neutral-300 ring-transparent appearance-none"
          >
            <span className="w-40 truncate">{getFilterNameById(selectedFilter) || 'Select filter'}</span>
            <span className="absolute right-0 flex items-center pr-2 -translate-y-1/2 pointer-events-none top-1/2">
              <IconChevronDown
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className="absolute z-50 w-full ml-9 max-h-[250px] min-w-[280px] left-1/2 -translate-x-1/2 py-1 mt-1 overflow-hidden text-base bg-white rounded-md shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm flex flex-col"
            >
              <div className="flex-1 pb-12 overflow-auto">
                <Listbox.Option
                  className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                  }`}
                  value=""
                  onClick={removeSelectedFilter}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {selected ? 'Select filter' : 'Deselect filters'}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <IconCheck className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
                {filtersListData?.filters?.map((filter: { _id: number, name: string }) => (
                  <Listbox.Option
                    key={filter._id}
                    className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`}
                    value={filter._id}
                  >
                    {({ selected }) => (
                      <>
                        <div
                          className={`flex w-full items-center justify-between truncate ${selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          <p className="max-w-40 truncate">{filter.name}</p>
                          <div className="flex items-center gap-1">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditFilter(String(filter?._id));
                              }}
                              type="button"
                            >
                              <IconEdit className="hover:text-green-700" size={20} />
                            </button>

                            <button
                              onClick={(e) => {
                                handleDeleteFIlter(
                                  String(filter?._id),
                                );
                                e.stopPropagation();
                              }}
                              type="button"
                            >
                              <IconTrash className="hover:text-red-600" size={20} />
                            </button>
                          </div>
                        </div>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                            <IconCheck className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
                <div className="fixed w-full h-10 p-1 bg-white bottom-2">
                  <Button
                    variant="default"
                    className="w-full"
                    onClick={() => setOpenEditModal(true)}
                    disabled={isFilterDeletionPending}
                  >
                    {isFilterDeletionPending ? <IconLoader2 className="animate-spin" size={18} />
                      : (
                        <>
                          <IconSquareRoundedPlus size={18} className="mr-2" />
                          Add Filter
                        </>
                      )}
                  </Button>
                </div>
              </div>
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>

      {openEditModal
        && (
        <EditFilterModal
          project_id={project_id}
          setOpenEditModal={setOpenEditModal}
          onReRun={onReRun}
          editFilterData={editFilterData}
        />
        )}
    </>
  );
}

export default FiltersSelect;
