import { InvalidateQueryFilters, useMutation, useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '@/axios/axiosInstance';
import { IformValues } from '@/components/userTable/UsersTable';
import { IRoleChangeWorkspace, ITransferOwnershipProps } from '@/components/Modals/WorkspaceInviteModal';
import Routes from '@/router/routes';
import { ISelectedUsers } from '@/components/Modals/RequestPublishingModal';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import axiosInstanceNoAuth from '@/axios/axiosInstanceNoAuth';
import { IPanelList } from '@/components/projects/single-project/share/ProjectSharePanel';

export const useMutationsGoToOtherOrganization = () => {
  const queryClient = useQueryClient();
  const remember_me = Cookies.get('remember_me');
  const options = remember_me === 'true' ? { expires: 14 } : { expires: 1 };

  return useMutation({
    mutationFn: async (data: { org_id: string, workspace_id: string }) => {
      const response = await axiosInstance.post(`/users/switch_context/${data?.org_id}/${data?.workspace_id}`, {
        organization_id: data?.org_id,
      });

      Cookies.set('token', response?.data?.token, options);
      Cookies.set('main_organization', response?.data?.organization_id, options);
      Cookies.set('fallback_workspace', data?.workspace_id, options);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
};

export const useMutateUserOrganizationInvitation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData: IformValues) => {
      const response = await axiosInstance.post('/organizations/invite', formData);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['organization_members'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateUserWorkspaceInvitation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData: IformValues) => {
      const response = await axiosInstance.post('/workspaces/share', formData);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['organization_members'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateTransferWorkspaceOwnership = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData: ITransferOwnershipProps) => {
      const response = await axiosInstance.post('/workspaces/transfer_ownership', formData);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['workspace_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutationsUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData: TUpdateUserDto) => {
      const response = await axiosInstance.post('/users/update_profile', formData);

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['user'] as InvalidateQueryFilters);
    },
  });
};

export const useMutationsChangeRole = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: TRoleChange) => {
      const response = await axiosInstance.post('/organizations/update_role', {
        role: data.role,
        user_id: data.user_id,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['organization_members'] as InvalidateQueryFilters);
    },
  });
};

export const useMutationsChangeRoleWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IRoleChangeWorkspace) => {
      const response = await axiosInstance.post('/workspaces/update_role', {
        role: data.role,
        user_id: data.user_id,
        workspace_id: data.workspace_id,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['organization_members'] as InvalidateQueryFilters);
      queryClient.invalidateQueries(['table_users'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateCreateWorkspace = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (formData: { name: string }) => {
      const response = await axiosInstance.post('/workspaces/create', formData);
      return response;
    },
    onSuccess: (success) => {
      navigate(`/organization/${success?.data?.organization_id}${Routes.workspace.path}/${success?.data?._id}`);
      queryClient.invalidateQueries(['get_all_workspaces'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateCreateProject = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { organization_id } = useParams();
  return useMutation({
    mutationFn: async (formData: { title: string, workspace_id: string | undefined }) => {
      const response = await axiosInstance.post('/projects/create', formData);
      return response;
    },
    onSuccess: (success) => {
      navigate(`/organization/${organization_id}${Routes.workspace.path}/${success?.data?.workspace_id}${Routes.project_design.path}/${success?.data?._id}`);
      queryClient.invalidateQueries(['all_projects_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateRenameWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData: { name: string, _id?: string | undefined }) => {
      const response = await axiosInstance.post('/workspaces/update', formData);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['workspace_data'] as InvalidateQueryFilters);
      queryClient.invalidateQueries(['get_all_workspaces'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateRenameOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ formData, organization_id }: { formData: { name?: string, isHidden?: boolean }, organization_id?: string }) => {
      const response = await axiosInstance.patch(`/organizations/update/${organization_id}`, formData);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['current_organization_data'] as InvalidateQueryFilters);
      queryClient.invalidateQueries(['all_organizations_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateRemoveUserFromWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ workspace, user_id }:
    { workspace: string | undefined, user_id: string }) => {
      const response = await axiosInstance.delete(`workspaces/remove_member/${workspace}/${user_id}`);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['workspace_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateMoveProject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData:
    { projectID: string, newWorkspaceId: string, oldWorkspaceId: string | undefined }) => {
      const response = await axiosInstance.patch(
        `/projects/move_project/${formData?.projectID}`,
        {
          newWorkspaceId: formData?.newWorkspaceId,
          oldWorkspaceId: formData?.oldWorkspaceId,
        },
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['workspace_data'] as InvalidateQueryFilters);
      queryClient.invalidateQueries(['get_all_workspaces'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateCopyProject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData:
    { projectID: string, targetWorkspaceId: string }) => {
      const response = await axiosInstance.post(
        `/projects/copy_project/${formData?.projectID}`,
        {
          targetWorkspaceId: formData?.targetWorkspaceId,
        },
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['workspace_data'] as InvalidateQueryFilters);
      queryClient.invalidateQueries(['get_all_workspaces'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateProjectUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData:
    { _id: string | undefined, confirmation_link?: string, provider_name?: string, is_integrated?: boolean }) => {
      const response = await axiosInstance.post(`/projects/update/${formData._id}`, {
        ...formData,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['single_project_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateProjectUpdatePanels = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ data, project_id }: { data: { panel: { [key: string]: IPanelList } }; project_id: string | undefined }) => {
      const response = await axiosInstance.post(`/projects/update/${project_id}`, data);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['single_project_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutatePublishProject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (project_id: string | undefined) => {
      const response = await axiosInstance.post(`/projects/publish/${project_id}`);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['single_project_data'] as InvalidateQueryFilters);
      queryClient.invalidateQueries(['all_projects_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateRequestPublishProject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData: { project_id: string | undefined, selected_users: ISelectedUsers }) => {
      const response = await axiosInstance.post(`/projects/request_publish/${formData.project_id}`, {
        ...formData.selected_users,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['single_project_data'] as InvalidateQueryFilters);
      queryClient.invalidateQueries(['all_projects_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateApprovePublishingProject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (project_id: string | undefined) => {
      const response = await axiosInstance.post(`/projects/approve_publish/${project_id}`);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['single_project_data'] as InvalidateQueryFilters);
      queryClient.invalidateQueries(['all_projects_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateDeleteTellet = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ projectId }: { projectId: string }) => {
      const response = await axiosInstance.delete(`/projects/delete/${projectId}`);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['workspace_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateReRunAnalyze = () => useMutation({
  mutationFn: async ({ project_id, f_id }: { project_id: string | undefined; f_id?: string | null }) => {
    const response = await axiosInstanceAnalyze.get(`/analyzer/run/${project_id}`, {
      params: { filter_id: f_id ?? undefined },
    });
    return response;
  },
});

export const useMutateDeleteFilter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ project_id, filter_id }: { project_id: string | undefined; filter_id?: string | null }) => {
      const response = await axiosInstanceAnalyze.delete(`/analyzer/filters/${project_id}/${filter_id}`);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['all_project_filters'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateDeleteWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ currentWorkspaceId }: { currentWorkspaceId: string }) => {
      const response = await axiosInstance.delete(`workspaces/delete/${currentWorkspaceId}`);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['get_all_workspaces'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateCompleteTellet = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ project_id }: { project_id: string | undefined }) => {
      const response = await axiosInstance.post(`projects/complete/${project_id}`);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['get_all_workspaces'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateDownloadConversationAsCSV = () => useMutation({
  mutationFn: async ({ conversation_id }: { conversation_id: string | null }) => {
    const response = await axiosInstanceAnalyze.get(`/analyzer/results/per_respondent/export/${conversation_id}`);
    return response;
  },
});

export const useMutateDownloadAllConversationsAsCSV = () => useMutation({
  mutationFn: async ({ project_id }: { project_id: string | undefined }) => {
    const response = await axiosInstanceAnalyze.get(`/analyzer/results/per_respondent/export_all/${project_id}`);
    return response;
  },
});

export const useMutateCreateFilter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: async ({ project_id, data }: { project_id: string | undefined, data: any }) => {
      const response = await axiosInstanceAnalyze.post(`/analyzer/filters/${project_id}/submit`, data);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['new_conversations_count'] as InvalidateQueryFilters);
      queryClient.invalidateQueries(['all_project_filters'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateEditFilter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: async ({ project_id, filter_id, data }: { project_id: string | undefined, filter_id: string | null, data: any }) => {
      const response = await axiosInstanceAnalyze.put(`/analyzer/filters/${project_id}/${filter_id}`, data);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['all_project_filters'] as InvalidateQueryFilters);
      queryClient.invalidateQueries(['new_conversations_count'] as InvalidateQueryFilters);
    },
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMutateAiSearchAskQuestion = (filter_id: string | null, conversation_selected: string | null, searchData: any) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async ({ project_id, inputQuestion }: { project_id: string | undefined, inputQuestion: string }) => {
      const response = await axiosInstanceAnalyze.post(`/analyzer/results/${project_id}/aisearch${filter_id ? `?filter_id=${filter_id}` : ''}`, {
        question: inputQuestion,
      });

      const params = new URLSearchParams();
      params.set('ai_search_question', response.data._id);

      const totalItems = searchData?.searches?.length || 1;
      const lastPage = Math.ceil(totalItems / 10);

      params.set('ai_search_question_page', String(lastPage));

      if (filter_id) {
        params.set('filter_id', filter_id);
      }
      if (conversation_selected) {
        params.set('selected_transcript', conversation_selected);
      }

      navigate(`?${params.toString()}`);

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['aisearch_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateDeleteAskedQuestion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ project_id, search_id, filter_id }:
    { project_id: string | undefined, search_id: string, filter_id: string | null }) => {
      const response = await axiosInstanceAnalyze.delete(
        `analyzer/results/${project_id}/aisearch`,
        { data: { search_id, filter_id } },
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['aisearch_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateResendEmailInvitation = () => useMutation({
  mutationFn: async (email: string | null) => {
    const response = await axiosInstance.post('/users/resend_verification_email', {
      email,
    });
    return response;
  },
});

export const useMutateEditCategories = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ project_id, formData, categoryType, question_id,
      //  filter_id,
      conversationsNumber }: {
      project_id: string | undefined,
      question_id: string | undefined,
      formData: ICategoryData[],
      // filter_id: string | null,
      categoryType: string | null,
      conversationsNumber: number
    }) => {
      const response = await axiosInstanceAnalyze.post(
        `/analyzer/quantification/${project_id}/submit?category_type=${categoryType}&conversations_num=${conversationsNumber}`,
        // ${filter_id ? `&filter_id=${filter_id}` : ''}`,
        {
          question_id,
          categories: formData,
        },
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['category_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateDelteCategories = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ project_id, question_id, categoryType }: {
      project_id: string | undefined,
      question_id: string | undefined,
      categoryType: string | null,
    }) => {
      const response = await axiosInstanceAnalyze.delete(`/analyzer/quantification/${project_id}/${question_id}?category_type=${categoryType}`);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['category_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateRenameTellet = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData: { projectName: string; project_id: string }) => {
      const response = await axiosInstance.post(`/projects/rename/${formData.project_id}`, { projectName: formData.projectName });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['workspace_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateReinviteToOrg = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData: { email: string; role: string }) => {
      const response = await axiosInstance.post(
        'organizations/resend_invitation',
        { email: formData.email, role: formData.role },
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['organization_members'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateTransferOrganizationalOwnership = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData: { user_id: string; organization_id: string }) => {
      const response = await axiosInstance.post(
        'organizations/transfer_ownership',
        { user_id: formData.user_id, organization_id: formData.organization_id },
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['roles_data_organization'] as InvalidateQueryFilters);
      queryClient.invalidateQueries(['organization_members'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateGenerateAiCategories = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ projectID, questionID,
      //  filter_id
    }:
    { projectID: string | undefined, questionID: string | undefined,
      //  filter_id: string | null
    }) => {
      const baseUrl = `/analyzer/quantification/${projectID}/generate_ai_categories?question_id=${questionID}`;
      // const url = filter_id ? `${baseUrl}?filter_id=${filter_id}` : baseUrl;
      const response = await axiosInstanceAnalyze.post(baseUrl);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['category_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateRequestAnotherAccountInvitation = () => useMutation({
  mutationFn: async ({ token }: { token: string | null }) => {
    const response = await axiosInstanceNoAuth.post(
      '/organizations/notify_inviter',
      undefined,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response;
  },
});

export const useMutateOverrideCategories = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ project_id, questionID, transformedData, workspace_id }: {
      project_id: string | undefined,
      questionID: string | undefined,
      transformedData: {
        conversation_id: string;
        categories: {
          id: string | undefined;
        }[];
      }[];
      workspace_id: string | undefined;
    }) => {
      const response = await axiosInstanceAnalyze.put(
        `/analyzer/quantification/${workspace_id}/${project_id}/${questionID}/manual-override`,
        transformedData,
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['category_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateCreateProjectTheme = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData: ICreateThemeData) => {
      const response = await axiosInstance.post('/themes', formData);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['all_brandings_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateUpdateProjectTheme = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ formData, theme_id }: { formData: ICreateThemeData, theme_id: string | null; }) => {
      const response = await axiosInstance.put(`/themes/${theme_id}`, formData);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['all_brandings_data'] as InvalidateQueryFilters);
    },
  });
};

export const useMutateDeleteProjectTheme = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (theme_id: string | null) => {
      const response = await axiosInstance.delete(`/themes/${theme_id}`);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['all_brandings_data'] as InvalidateQueryFilters);
    },
  });
};
