import * as React from 'react';
import { format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';

function DateCalendarFilter({
  onSelect,
  hasError,
  defaultValue,
}: React.HTMLAttributes<HTMLDivElement> & {
  onSelect: (range: Date | undefined) => void;
  hasError?: boolean;
  defaultValue?: Date | undefined;
}) {
  const [date, setDate] = React.useState<Date | undefined>(defaultValue || undefined);

  const handleDateChange = (selectedDate: Date | undefined) => {
    setDate(selectedDate);
    onSelect(selectedDate);
  };

  React.useEffect(() => {
    if (defaultValue) {
      setDate(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn(
            'min-w-[230px] justify-start text-left font-normal',
            !date && 'text-muted-foreground',
            hasError && 'border-red-600',
          )}
        >
          <CalendarIcon className="mr-2 min-w-4 min-h-4" />
          {date ? format(date, 'dd/MM/yyyy') : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 z-[99999]">
        <Calendar
          mode="single"
          selected={date}
          onSelect={handleDateChange}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}

export default DateCalendarFilter;
