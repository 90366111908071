import { Listbox, Transition } from '@headlessui/react';
import { IconChevronDown, IconPlus } from '@tabler/icons-react';
import { Fragment } from 'react';
import { cn } from '@/lib/utils';

interface IListProps {
  value: string | undefined;
  onChange: (value: string) => void;
  isDisabled: boolean;
  direction?: string;
  data: string[];
  placeholder: string;
}

function FilterKeysDropdown({ value, onChange, isDisabled, data, direction, placeholder }: IListProps) {
  return (
    <Listbox value={value || ''} onChange={onChange} disabled={isDisabled}>
      <div className="relative mt-1">
        <Listbox.Button className="flex relative cursor-pointer h-10 w-full items-center justify-between rounded-md border-2 shadow
        border-neutral-200 bg-gray-50 px-3 py-2 text-sm ring-offset-white placeholder:text-neutral-500
        focus:outline-none focus:ring-1 disabled:cursor-not-allowed text-black
        disabled:opacity-50 [&>span]:line-clamp-1 dark:border-neutral-800 dark:bg-neutral-950
          dark:placeholder:text-neutral-400 dark:focus:ring-neutral-300 ring-transparent appearance-none"
        >
          <span className="block truncate">
            {data?.length === 0 ? 'All values are in use' : (
              <p className="flex items-center gap-1">
                <IconPlus size={18} />
                {placeholder}
              </p>
            )}
          </span>
          <span className="absolute right-0 flex items-center pr-2 -translate-y-1/2 pointer-events-none top-1/2">
            <IconChevronDown
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className={cn(
            'absolute z-50 w-full py-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm',
            direction === 'bottom' ? 'bottom-full mb-1' : 'mt-1',
          )}
          >
            {data?.map((item) => (
              <Listbox.Option
                key={item}
                className={({ active }) => `relative z-50 cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                }`}
                value={item}
              >

                <span
                  className="block font-normal truncate"
                >
                  {item}
                </span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

export default FilterKeysDropdown;
