import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { IconLoader2 } from '@tabler/icons-react';
import { useQuery, InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { AxiosError } from 'axios';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import axiosInstance from '@/axios/axiosInstance';
import NewspaperIcon from '@/components/icons/NewspaperIcon';
import PaperAirplaneIcon from '@/components/icons/PaperAirplaneIcon';
import QuestionMarkCircleIcon from '@/components/icons/QuestionMarkCircleIcon';
import SparklesIcon from '@/components/icons/SparklesIcon';
import Squares2X2Icon from '@/components/icons/Squares2X2Icon';
import UserIcon from '@/components/icons/UserIcon';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { toast } from '@/components/ui/use-toast';
import { EProjectStatus, EStatusCode } from '@/enums';
import useTranslation from '@/hooks/useTranslation';
import { cn } from '@/lib/utils';
import { useMutateReRunAnalyze } from '@/reactQuery/post';
import useErrorStore from '@/store/ErrorStore';
import useReportStore from '@/store/ReportStore';
import useResultTabStore from '@/store/ResultsTabStore';
import AISearch from '../Content/AISearch';
import ConversationsTranscripts from '../Content/ConversationsTranscripts';
import Overview from '../Content/Overview';
import Questions from '../Content/Questions';
import ProjectReport from './ProjectReport';
import FiltersSelect from '@/components/projects/single-project/filters/FiltersSelect';

function ProjectResults() {
  const { lang } = useTranslation();
  const { activeTab, setActiveTab } = useResultTabStore();
  const { project_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const filter_id = searchParams.get('filter_id');
  const queryClient = useQueryClient();
  const { clearReportData } = useReportStore();
  const { currentTheme } = useTheme();
  const { setShowError } = useErrorStore();
  const [rerunCacheState, setRerunCacheState] = useState('');
  const [rerunLoadingState, setRerunLoadingState] = useState<'idle' | 'loading' | 'done'>('idle');
  const rerunChacheStateLocalStorage = localStorage.getItem(`rerun_state_${project_id}`);
  const rerunChacheStateLocalStorageFilter = localStorage.getItem(`rerun_state_${project_id}_filter_id`);
  const isRerunLoading = rerunLoadingState === 'loading';

  const { mutateAsync: mutateAsyncReRun, isPending: isPendingReRun } = useMutateReRunAnalyze();

  const addQueryParams = (params: Record<string, string>) => {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    setSearchParams(searchParams);
  };

  const { data: singleProjectData, error: projectDataError } = useQuery({
    queryFn: () => axiosInstance.get(`projects/get/${project_id}`),
    queryKey: ['single_project_data', { project_id }],
    enabled: !!project_id,
    select: (data) => data?.data,
  });

  const { data: newConversationsCount } = useQuery({
    queryFn: () => axiosInstanceAnalyze.get(`/analyzer/run/${project_id}/indicator/${rerunCacheState || rerunChacheStateLocalStorage || 'idle'}/${filter_id ? `?filter_id=${filter_id}` : ''}`),
    queryKey: ['new_conversations_count', { filter_id, project_id }],
    select: (data) => data?.data,
    enabled: rerunCacheState !== null,
    refetchInterval: 10000,
  });

  const onReRun = async (f_id?: string | null): Promise<void> => {
    try {
      const res = await mutateAsyncReRun({ project_id, f_id });
      setRerunLoadingState(res?.data?.state);
      setRerunCacheState(res?.data?.state_id);
      localStorage.setItem(`rerun_state_${project_id}`, res?.data?.state_id);
      if (f_id) localStorage.setItem(`rerun_state_${project_id}_filter_id`, f_id);
    } catch {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const analysisLanguage = singleProjectData?.analysis_language;

  const showFilters = singleProjectData?.status === EProjectStatus.COMPLETED;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => { clearReportData(); setActiveTab('overview_tab'); }, []);

  useEffect(() => {
    setRerunLoadingState(newConversationsCount?.state);
  }, [newConversationsCount]);

  useEffect(() => {
    if (rerunLoadingState === 'done') {
      queryClient.invalidateQueries(['overall_data'] as InvalidateQueryFilters);
      queryClient.invalidateQueries(['question_insights'] as InvalidateQueryFilters);
      queryClient.invalidateQueries(['question_transcripts'] as InvalidateQueryFilters);
      queryClient.invalidateQueries(['category_data'] as InvalidateQueryFilters);
      queryClient.invalidateQueries(['new_conversations_count'] as InvalidateQueryFilters);
      localStorage.removeItem(`rerun_state_${project_id}`);
      localStorage.removeItem(`rerun_state_${project_id}_filter_id`);
      if (singleProjectData?.status !== EProjectStatus.COMPLETED) {
        toast({ description: 'Re-run is done!' });
      } else {
        if (rerunChacheStateLocalStorageFilter) addQueryParams({ filter_id: rerunChacheStateLocalStorageFilter });
        toast({ description: 'Filter is successfully created and applied to your project.' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rerunLoadingState, queryClient, project_id, singleProjectData, rerunChacheStateLocalStorageFilter]);

  useEffect(() => {
    if ((projectDataError as AxiosError)?.status === EStatusCode.BAD_REQUEST) {
      setShowError(true);
    }
  }, [projectDataError, setShowError]);

  return (
    <main
      style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
      className="flex flex-col w-full h-full"
    >
      {/* tabs */}
      <Tabs defaultValue="overview_tab" className="w-full" value={activeTab} onValueChange={(value) => [setActiveTab(value), scrollToTop()]}>
        <div
          style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
          className="fixed flex items-center z-[99] justify-between w-full py-2 -mt-[9px] border-b"
        >
          <TabsList
            style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
            className="relative flex items-center justify-start w-full h-fit max1200:flex-wrap max1200:gap-5"
          >
            <TabsTrigger
              style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
              value="overview_tab"
              className="!shadow-none transition-all ease-in-out duration-300 w-52 max1200:w-40 font-bold text-base relative"
            >
              <div className="flex items-center gap-1.5">
                <Squares2X2Icon
                  style={{ color: currentTheme?.primary }}
                  className="size-5"
                />
                <p className="epilogue-font">
                  {lang.get('msg.overview')}
                </p>
              </div>
              <hr
                style={{ backgroundColor: currentTheme?.primary }}
                className={`absolute -bottom-3.5 w-full transition-all ease-in-out duration-300 h-1 ${activeTab === 'overview_tab' ? 'opacity-100' : 'opacity-0'
                }`}
              />
            </TabsTrigger>
            <TabsTrigger
              value="questions_tab"
              style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
              className="!shadow-none transition-all ease-in-out duration-300 w-52 max1200:w-40 font-bold text-base relative "
            >
              <div className="flex items-center gap-1.5">
                <QuestionMarkCircleIcon
                  style={{ color: currentTheme?.primary }}
                  className="size-5"
                />
                <p className="epilogue-font">
                  {lang.get('msg.questions')}
                </p>
              </div>
              <hr
                style={{ backgroundColor: currentTheme?.primary }}
                className={`absolute -bottom-3.5 w-full transition-all ease-in-out duration-300 h-1 ${activeTab === 'questions_tab' ? 'opacity-100' : 'opacity-0'
                }`}
              />
            </TabsTrigger>
            <TabsTrigger
              value="transcripts_tab"
              style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
              className="!shadow-none transition-all ease-in-out duration-300 w-52 max1200:w-40 font-bold text-base relative "
            >
              <div className="flex items-center gap-1.5">
                <UserIcon
                  style={{ color: currentTheme?.primary }}
                  className="size-5"
                />
                <p className="epilogue-font">
                  {lang.get('msg.transcripts')}
                </p>
              </div>
              <hr
                style={{ backgroundColor: currentTheme?.primary }}
                className={`absolute -bottom-3.5 w-full transition-all ease-in-out duration-300 h-1 ${activeTab === 'transcripts_tab' ? 'opacity-100' : 'opacity-0'
                }`}
              />
            </TabsTrigger>
            <TabsTrigger
              value="ai_search_tab"
              style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
              className="!shadow-none transition-all ease-in-out duration-300 w-52 max1200:w-40 font-bold text-base relative "
            >
              <div className="flex items-center gap-1.5">
                <SparklesIcon
                  style={{ color: currentTheme?.primary }}
                  className="size-5"
                />
                <p className="epilogue-font">
                  {lang.get('msg.aiSearch')}
                </p>
              </div>
              <hr
                style={{ backgroundColor: currentTheme?.primary }}
                className={`absolute -bottom-3.5 w-full transition-all ease-in-out duration-300 h-1 ${activeTab === 'ai_search_tab' ? 'opacity-100' : 'opacity-0'
                }`}
              />
            </TabsTrigger>
            <TabsTrigger
              value="report_tab"
              style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
              className="!shadow-none transition-all ease-in-out duration-300 w-52 max1200:w-40 font-bold text-base relative "
            >
              <div className="flex items-center gap-1.5">
                <NewspaperIcon
                  style={{ color: currentTheme?.primary }}
                  className=" size-5"
                />
                <p className="epilogue-font">
                  {lang.get('msg.report')}
                </p>
              </div>
              <hr
                style={{ backgroundColor: currentTheme?.primary }}
                className={`absolute -bottom-3.5 w-full transition-all ease-in-out duration-300 h-1 ${activeTab === 'report_tab' ? 'opacity-100' : 'opacity-0'
                }`}
              />
            </TabsTrigger>
          </TabsList>

          {singleProjectData?.status !== EProjectStatus.COMPLETED && (
            <Button
              variant="default"
              disabled={isPendingReRun || isRerunLoading}
              className="relative flex epilogue-font items-center gap-2 mr-[4%] font-bold px-7"
              onClick={() => onReRun()}
            >
              {
                (isPendingReRun || isRerunLoading)
                  ? <IconLoader2 className="animate-spin" size={20} />
                  : <PaperAirplaneIcon className="size-4" />
              }
              {(isPendingReRun || isRerunLoading) ? 'Re-running'
                : 'Re-run'}
              {rerunLoadingState !== 'loading' && newConversationsCount?.numberOfUnRunConversations > 0 && (
                <span className="absolute flex items-center justify-center h-5 px-1.5 text-xs text-white bg-red-600 rounded-full w-fit -top-1 -right-1.5">
                  {newConversationsCount?.numberOfUnRunConversations}
                </span>
              )}
            </Button>
          )}

          {singleProjectData?.status === EProjectStatus.COMPLETED && isRerunLoading && (
            <Button
              variant="default"
              disabled
              className="flex items-center gap-2 mr-[4%] font-bold px-7"
            >
              <IconLoader2 className="animate-spin" size={20} />
              <p className="epilogue-font">{lang.get('msg.applyingFilter')}</p>
            </Button>
          )}
        </div>

        {/* filters */}
        {showFilters && (
          <div
            style={{ borderColor: currentTheme?.['primary-text'] }}
            className="flex items-center justify-start w-full h-16 gap-3 mx-auto mt-12 border-b md900:mt-24 md600:mt-40 border-light-gray/20"
          >
            {/* select filter */}
            <FiltersSelect onReRun={onReRun} />
          </div>
        )}

        <TabsContent value="overview_tab" className={cn(showFilters ? 'pt-5' : 'pt-20 max1050:pt-28 min505:pt-72 md700:pt-44', 'pb-10 !ring-transparent !ring-0')}>
          <Overview
            isPendingReRun={isPendingReRun}
            analysisLanguage={analysisLanguage}
            singleProjectData={singleProjectData}
            newConversationsCount={newConversationsCount?.numberOfUnRunConversations}
            isRerunLoading={isRerunLoading}
            onReRun={onReRun}
          />
        </TabsContent>
        <TabsContent value="transcripts_tab" className={cn(showFilters ? 'pt-1' : 'pt-14 max1050:pt-28 min505:pt-72 md700:pt-44', 'pb-10 !ring-transparent !ring-0')}>
          <ConversationsTranscripts />
        </TabsContent>
        <TabsContent value="questions_tab" className={cn(showFilters ? 'pt-5' : 'pt-20 max1050:pt-28 min505:pt-72 md700:pt-44', 'pb-10 !ring-transparent !ring-0')}>
          <Questions
            analysisLanguage={analysisLanguage}
            isPendingReRun={isPendingReRun}
            singleProjectData={singleProjectData}
            newConversationsCount={newConversationsCount?.numberOfUnRunConversations}
            isRerunLoading={isRerunLoading}
            onReRun={onReRun}
          />
        </TabsContent>
        <TabsContent value="ai_search_tab" className={cn(showFilters ? 'pt-5' : 'pt-20 max1050:pt-28 min505:pt-72 md700:pt-44', 'pb-10 !ring-transparent !ring-0')}>
          <AISearch />
        </TabsContent>
        <TabsContent value="report_tab" className={cn(showFilters ? 'pt-5' : 'pt-20 max1050:pt-28 min505:pt-72 md700:pt-44', 'pb-10 !ring-transparent !ring-0')}>
          <ProjectReport
            isPendingReRun={isPendingReRun}
            analysisLanguage={analysisLanguage}
          />
        </TabsContent>
      </Tabs>
    </main>
  );
}

export default ProjectResults;
