/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { IconArrowUp, IconArrowDown, IconArrowsUpDown, IconAdjustmentsHorizontal } from '@tabler/icons-react';
import { CalendarIcon, ChevronDown } from 'lucide-react';
import { useDebounce } from 'use-debounce';
import { DateRange } from 'react-day-picker';
import { format, isWithinInterval, parseISO, startOfDay, endOfDay } from 'date-fns';
import axiosInstance from '@/axios/axiosInstance';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { LangContext } from '@/context/LangProvider';
import { EPanelIntegration } from '@/enums';
import { QuestionAnswersColumns } from './QuestionAnswersColumns';
import { Input } from '../ui/input';
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from '../ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import DataTablePagination from '../paginations/DataTablePagination';

interface ITableProps {
  selectedQuestionTranscripts: ISelectedQuestion[] | undefined;
  questionID: string | undefined;
}

interface ICategory {
  label: string;
  color: string;
  prompt: string;
}

interface ISelectedQuestion {
  gender?: string;
  age?: number;
  started: string;
  conversation_id: string;
  questions: string;
  category: ICategory[]
}

const useContainsFilterFn = () => useCallback((row: any, columnId: string, filterValue: any) => {
  if (!filterValue) return true; // If no filter, include the row

  if (columnId === 'started') {
    const { from, to } = filterValue;

    // Ensure from and to are valid dates
    const startDate = from ? (typeof from === 'string' ? parseISO(from) : from) : null;
    const endDate = to ? (typeof to === 'string' ? parseISO(to) : to) : null;

    // Ensure rowValue is a Date object or ISO string
    const rowValue = row.getValue(columnId);
    const rowDate = typeof rowValue === 'string' ? parseISO(rowValue) : rowValue;

    // Check if the date falls within the specified range, including boundaries
    if (startDate && endDate) {
      return isWithinInterval(rowDate, { start: startOfDay(startDate), end: endOfDay(endDate) });
    }

    return false;
  }

  const lowerFilter = filterValue?.toLowerCase();
  const rowValue = row.getValue(columnId);

  if (columnId === 'category') {
    if (Array.isArray(rowValue)) {
      return rowValue.some((category) => category?.label?.toLowerCase().includes(lowerFilter));
    }
    return rowValue?.label?.toLowerCase().includes(lowerFilter) || false;
  }

  return String(rowValue).toLowerCase().includes(lowerFilter);
}, []);

export default function QuestionAnswersTable({ selectedQuestionTranscripts, questionID }: ITableProps) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    gender: false,
    age: false,
    started: false,
  });
  const [rowSelection, setRowSelection] = useState({});
  const [selectedCategories, setSelectedCategories] = useState<{ label: string; value: string }[]>([]);
  const { lang } = useContext(LangContext);
  const { project_id, workspace_id } = useParams();
  const [searchParams] = useSearchParams();
  const filter_id = searchParams.get('filter_id');
  const [areAllCategoriesZeroSingle, setAreAllCategoriesZeroSingle] = useState(false);
  const [areAllCategoriesZeroMulti, setAreAllCategoriesZeroMulti] = useState(false);
  const [inputValue, setInputValue] = useState<Record<string, string>>({});
  const [debouncedInputValue] = useDebounce(inputValue, 400);
  const [date, setDate] = useState<DateRange | undefined>({
    from: undefined,
    to: undefined,
  });

  const containsFilterFn = useContainsFilterFn();

  const { data: singleProjectData } = useQuery({
    queryFn: () => axiosInstance.get(`projects/get/${project_id}`),
    queryKey: ['single_project_data', { project_id }],
    select: (data) => data?.data,
  });

  const { data: categoryData } = useQuery({
    queryFn: () => {
      const baseUrl = `/analyzer/quantification/${project_id}/${questionID}`;
      const url = filter_id ? `${baseUrl}?filter_id=${filter_id}` : baseUrl;
      return axiosInstanceAnalyze.get(url);
    },
    queryKey: ['category_data', { questionID, filter_id, project_id }],
    enabled: !!project_id && !!questionID,
    select: (category_data) => category_data?.data,
  });

  const transformedCategories = useMemo(() => categoryData?.categories?.map((category: { id: string, label: string }) => ({
    label: category?.label,
    value: category?.id,
  })) || [], [categoryData]);

  const areAllCountsZeroCheck = useCallback(() => {
    // Check if all counts in 'categories' are 0
    const allCategoriesZero = categoryData?.categories?.every(
      (category: { count: number }) => category?.count === 0,
    );

    // Check if all counts in 'categories_multi' are 0
    const allCategoriesMultiZero = categoryData?.categories_multi.every(
      (category: { count: number }) => category?.count === 0,
    );

    // Set state based on whether all counts are zero
    setAreAllCategoriesZeroSingle(allCategoriesZero);
    setAreAllCategoriesZeroMulti(allCategoriesMultiZero);
  }, [categoryData, setAreAllCategoriesZeroSingle]);

  const isCategoryDataExists = categoryData?.categories?.length !== 0 || categoryData?.categories_multi?.length !== 0;
  const categoryDataSingle = categoryData?.categories;
  const categoryDataMultiple = categoryData?.categories_multi;
  const isPanel = singleProjectData?.panel_integration !== EPanelIntegration.NONE;

  // Filter logic
  const filteredTranscripts = useMemo(() => {
    if (selectedCategories?.length === 0) {
      return selectedQuestionTranscripts;
    }

    return selectedQuestionTranscripts?.filter((transcript) => {
      // Normalize transcript.category to be an array
      const transcriptCategories = Array.isArray(transcript?.category)
        ? transcript?.category
        : [transcript?.category];

      const selectedCategoryLabels = selectedCategories?.map((category) => category?.label?.toLowerCase());

      const transcriptCategoryLabels = transcriptCategories?.map((cat) => cat?.label?.toLowerCase());

      return transcriptCategoryLabels.some((label) => selectedCategoryLabels?.includes(label));
    });
  }, [selectedQuestionTranscripts, selectedCategories]);

  const columns = QuestionAnswersColumns(
    isPanel,
    categoryDataSingle,
    questionID,
    categoryDataMultiple,
    isCategoryDataExists,
    areAllCategoriesZeroSingle,
    areAllCategoriesZeroMulti,
    transformedCategories,
    setSelectedCategories,
    selectedCategories,
    project_id,
    workspace_id,
  ).map((column) => ({
    ...column,
    filterFn: containsFilterFn,
  }));

  const table = useReactTable({
    data: filteredTranscripts || [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
  });

  useEffect(() => {
    Object.entries(debouncedInputValue).reduce((acc, [columnId, filterValue]) => {
      table.getColumn(columnId)?.setFilterValue(filterValue);
      return acc;
    }, {});
  }, [debouncedInputValue, table]);

  const handleInputChange = useCallback((columnId: string, value: string) => {
    setInputValue((prev) => ({
      ...prev,
      [columnId]: value,
    }));
  }, []);

  useEffect(() => {
    const startedColumn = table.getColumn('started');
    if (startedColumn) {
      if (date?.from && date?.to) {
        startedColumn.setFilterValue({ from: date.from, to: date.to });
      } else {
        startedColumn.setFilterValue(undefined);
      }
    }
  }, [date, table]);

  useEffect(() => {
    areAllCountsZeroCheck();
  }, [categoryData, areAllCountsZeroCheck]);

  return (
    <div className="w-full mt-4">
      <div className="relative z-20 flex items-center justify-end w-full mb-3">
        <div className="flex items-end">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto">
                <IconAdjustmentsHorizontal size={16} className="mr-2" />
                View
                <ChevronDown className="w-4 h-4 ml-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .map((column) => (
                  <DropdownMenuCheckboxItem
                    key={column?.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) => column.toggleVisibility(!!value)}
                  >
                    {column?.id}
                  </DropdownMenuCheckboxItem>
                ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div className="border rounded-md">
        <Table className="text-dark-text">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    <div className="flex items-center w-full gap-2">
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                      {header?.id !== 'actions' && header?.id !== 'category'
                        && (
                          <div>
                            {{
                              asc: <IconArrowUp size={18} />,
                              desc: <IconArrowDown size={18} />,
                            }[header.column.getIsSorted() as string] ?? <IconArrowsUpDown size={18} />}
                          </div>
                        )}
                      {header?.id === 'category'
                        && (
                          <div className="self-start mt-3 -ml-6">
                            {{
                              asc: <IconArrowUp size={18} />,
                              desc: <IconArrowDown size={18} />,
                            }[header.column.getIsSorted() as string] ?? <IconArrowsUpDown size={18} />}
                          </div>
                        )}
                    </div>
                    {header?.id !== 'actions'
                      && header?.id !== 'category'
                      && header?.id !== 'started'
                      && (
                        <Input
                          placeholder={`Search ${header?.id}...`}
                          value={(inputValue[header.id] as string) || ''}
                          onChange={(e) => handleInputChange(header.id, e.target.value)}
                          className="w-full mb-2.5 min-w-52 max-w-52 bg-white"
                        />
                      )}

                    {header?.id === 'started' && (
                      <div className="grid gap-2 mb-3">
                        <Popover>
                          <PopoverTrigger asChild>
                            <Button
                              id="date"
                              variant="outline"
                              className={cn(
                                'w-56 justify-start text-left font-normal',
                                !date && 'text-muted-foreground',
                              )}
                            >
                              <CalendarIcon className="w-4 h-4 mr-2" />
                              {/* eslint-disable-next-line no-nested-ternary */}
                              {date?.from ? (
                                date.to ? (
                                  <>
                                    {format(date.from, 'dd/MM/yy')}
                                    {' '}
                                    -
                                    {' '}
                                    {format(date.to, 'dd/MM/yy')}
                                  </>
                                ) : (
                                  format(date.from, 'dd/MM/yy')
                                )
                              ) : (
                                <span>Pick a date</span>
                              )}
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                              initialFocus
                              mode="range"
                              defaultMonth={date?.from}
                              selected={date}
                              onSelect={setDate}
                              numberOfMonths={1}
                            />
                          </PopoverContent>
                        </Popover>
                      </div>
                    )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table?.getRowModel()?.rows?.length ? (
              table?.getRowModel()?.rows?.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns?.length} className="h-24 text-center">
                  {lang.get('msg.noResults')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
}
